// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

body {
    padding-right: 0px !important;
}

.description {
    margin-bottom: 14px;
}

.font-weight-5 {
    font-weight: 300 !important;
}

.experience-desc {
    font-weight: 300 !important;
    color: #fff !important;
}

.font-weight-6 {
    font-weight: 600;
}

.modal-content {
    margin: 1.5rem;
    border-radius: 10px;
}

.flex-center {
    display: flex;
    align-items: center;
}

.border-div {
    position: relative;
    padding-bottom: 10px;
}

.border-div::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 15%;
    height: 1px;
    background-color: rgb(255, 255, 255);
}

.text-regular {
    color: rgba(198, 201, 216, .75);
    font-size: 16px;

    .title {
        font-size: large;
    }

    .time {
        // font-size: small;
        font-size: 0.8rem;
    }

    .place {
        // text-align: justify;
        font-weight: 200 !important;
        font-size: .8rem;
        font-style: italic !important;
    }

    .m-top-05 {
        // text-align: end;
        margin-top: .3rem;
    }
}

.modal-header {
    padding: 0.4rem .7rem;
}

.modal-body {
    padding: 0.5rem 2.5rem 2.5rem 2.5rem;
}

.color-black {
    color: black !important;
}

.cursor-pointer {
    cursor: pointer;
}

.card-role {
    height: 4.5rem;
}

.margin-top-40 {
    margin-top: 40px;
}

.exp-time {
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: .3rem;
}

.color-BW {
    color: black !important;
    transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.color-BW:hover {
    color: white !important;
    transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.color-BW:hover .BW {
    color: white !important;
    transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.BW {
    color: black !important;
    transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rn-paralax-service .service__style--2 {
    background: rgba(255, 255, 255, .5);
}

a.btn-transparent::after {
    // background: #000000;
    background: #896b55
}

a.btn-transparent::before {
    background: #ffffff;
}

.service.service__style--2::before {
    background-image: linear-gradient(to right, #896b55, #5a4738);
}

.rn-paralax-service .service__style--2:hover {
    border: 0px solid #797776;
}

// .section-title.service-style--2 p {
//     padding: 0 9%;
// }

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .service.service__style--2::before {
        background-image: none;
        color: black !important;
    }

    .color-BW {
        color: black !important;
    }

    .color-BW:hover {
        color: black !important;
    }

    .color-BW:hover .BW {
        color: black !important;
    }

    .rn-paralax-service .service__style--2:hover {
        border: 2px solid #797776;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
    .service.service__style--2::before {
        // background-image: linear-gradient(to right, #896b55, #5a4738);
        background-image: linear-gradient(to right, #1a0d03d9, #1a0d03d9);
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    .service.service__style--2::before {
        // background-image: linear-gradient(to right, #896b55, #5a4738);
        background-image: linear-gradient(to right, #1a0d03d9, #1a0d03d9);
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
    .card-role {
        height: 4.5rem;
    }

    .service.service__style--2::before {
        // background-image: linear-gradient(to right, #896b55, #5a4738);
        background-image: linear-gradient(to right, #1a0d03d9, #1a0d03d9);
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .card-role {
        height: 4.5rem;
    }

    .service.service__style--2::before {
        // background-image: linear-gradient(to right, #896b55, #5a4738);
        background-image: linear-gradient(to right, #1a0d03d9, #1a0d03d9);
    }
}